import { Form as BootstrapForm } from 'react-bootstrap';
import Select from 'react-select';

export function MultiSelectDropdown ({ id, label, options, description, value, setValue } : {
  id : string;
  label : string;
  options : any;
  description? : string;
  value: any[];
  setValue: (value: any) => void;
} )  {

  const handleChange = (selectedOptions: any) => {
    setValue(selectedOptions);
  };

  console.log({value, options})

  return (
    <BootstrapForm.Group controlId={id} className='mb-3'>
      <BootstrapForm.Label>{label}</BootstrapForm.Label>
      <Select
        id={id}
        options={options}
        isMulti
        value={value}
        onChange={handleChange}
        // styles={customStyles}
      />
      {description && <BootstrapForm.Text muted>
      {description}
    </BootstrapForm.Text>}
    </BootstrapForm.Group>
  );
};