import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

interface Plan {
  name: string;
  price: string;
  features: string[];
}

interface PlanDisplayProps {
  plans: Plan[];
}

const PlanDisplay: React.FC<PlanDisplayProps> = ({ plans }) => {
  return (
    <Container>
      <Row className="gy-4 justify-content-center">
        {plans.map((plan, idx) => (
          <Col key={idx} xs={12} md={12/plans.length} className="mb-4">
            <Card className="h-100 shadow-sm">
              <Card.Header 
                className="bg-primary text-white text-left"
                style={{ fontSize : 20, fontWeight : 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {plan.name}
              </Card.Header>
              <Card.Body>
                <Card.Title className='mb-5 fs-2x'>{plan.price}</Card.Title>
                <ul className="list-unstyled">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="mb-2">
                      <div className='d-flex align-items-center'>
                      <i className="bi bi-check-circle-fill text-success me-5"></i><div>{feature}</div>

                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PlanDisplay;
