import axios from 'axios'
import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import { setupAxios } from './modules/auth'

import ErrorBoundary from '../ErrorBoundary'

const App = () => {

  const {currentAccount} = useAuth();

  setupAxios(axios, currentAccount)
  return (
    // <ErrorBoundary>
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
    // </ErrorBoundary>
  )
}

export {App}
