
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getPerformance, trace as perfTrace } from "firebase/performance";
import { onFID } from 'web-vitals';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "sulfi-saul.firebaseapp.com",
  projectId: "sulfi-saul",
  storageBucket: "sulfi-saul.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
 
const app = initializeApp(firebaseConfig);

const performance = getPerformance(app);

onFID((metric) => {
  const fidTrace = perfTrace(performance, 'FID');
  fidTrace.start();
  fidTrace.putAttribute('FID', String(metric.value));
  fidTrace.stop();
});

export const auth = getAuth(app);
export default app;