// import { MenuItem } from './MenuItem'
// import { useIntl } from 'react-intl'

import { useAuth } from '../../../../app/modules/auth'

export function MenuInner() {
  // const intl = useIntl()

  const { accounts, currentAccount, setCurrentAccount } = useAuth();

  return (
    <div className='d-flex align-items-center'>
      <div className='me-3' >
        <select
          className='text-primary-dark form-select form-select-sm bg-primary-light'
          name='layout-builder[layout][header][width]'
          value={currentAccount?.id || ''}
        onChange={(e) =>
          {
            setCurrentAccount(accounts?.find(account => account.id === e.target.value))
          }
        }
        >
          {accounts?.map((account) => (<option value={account.id}>{account.name}</option>))}
        </select>
      </div>
      {/* <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' /> */}
    </div>
  )
}
