import { Channel } from './Channel'
import { IProcessBasic } from './Process'

export enum TrackingStatusColor {
  active = 'success',
  paused = 'warning',
  processing = 'primary',
  error = 'danger'
}

export interface Tracking {
  id: string;
  status: 'active' | 'paused' | 'processing' | 'error';
  status_message: string;
  state: string;
  frequency : 'daily' | 'weekly';
  type: 'process_number' | 'oab' | 'cpf' | 'cnpj' | 'keyword';
  created_at: string;
  updated_at: string;
  name: string;
  process: {
    id: string,
    name: string,
    number: string,
    id_escavador: string,
    link_escavador: string,
    state: string,
    journal: string,
    journal_name: string
  },
  aux_keywords : {
    keyword : string,
    condition : 'contains' | 'not_contains' | 'contains_some' | 'variations'
  }[],
  channels : Channel[],
  journal_ids : number[]
}

export interface ITrackingBasic {
  name: string
  type: 'cpf' | 'cnpj' | 'process_number' | 'oab' | 'keyword'
}


export interface ICreateTrackingData {
  name?: string
  type: 'cpf' | 'cnpj' | 'process_number' | 'oab' | 'keyword'
  identifier? : string;
  state? : string;
  court? : string;
  frequency? : 'daily' | 'weekly'
  channels? : string[]
  process? : IProcessBasic
  journal_ids? : number[]
  aux_keywords : {
    keyword : string,
    condition : 'contains' | 'not_contains' | 'contains_some' | 'variations'
  }[]
}


export const defaultCreateTrackingData: ICreateTrackingData = {
  name : undefined,
  type : 'process_number',
  identifier : undefined,
  state : undefined,
  frequency : 'weekly',
  channels : [],
  aux_keywords : [],
  journal_ids: []
}

export type StepProps = {
  data: ICreateTrackingData
  updateData: (fieldsToUpdate: Partial<ICreateTrackingData>) => void
  hasError: boolean
}
