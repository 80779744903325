import {
  TrackingsTable,
  TilesWidget2,
  ChannelsList
} from '../../../_metronic/partials/widgets'

const DashboardPage = () => (
  <>
    <div className='row g-5 g-xl-12'>

      <div className='col-xl-12'>

        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>

          <div className='col-xl-6'>
            <TilesWidget2 title='Acompanhe Seus Processos Judiciais' cta = 'Criar Monitoramento' className='card-xl-stretch mb-6 mb-xl-6' />
          </div>
        </div>

      </div>
    </div>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-12'>
    <div className='col-xl-8'>
        <TrackingsTable className='card-xxl-stretch mb-5 mb-xl-12' />
      </div>
      <div className='col-xxl-4'>
        <ChannelsList className='card-xxl-stretch mb-xl-3' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const DashboardWrapper = () => {
  // const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
