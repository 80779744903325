import { ITrackingBasic } from './Tracking';

export enum ChannelIcon {
  whatsapp = 'whatsapp',
  telegram = 'telegram',
  email = 'send'
}

export enum ChannelIconColor {
  whatsapp = 'success',
  telegram = 'primary',
  email = 'danger'
}

export enum ChannelStatusColor {
  active = 'success',
  pending = 'warning',
  error = 'danger'
}

export enum ChannelSatusText {
  active = 'Ativo',
  pending = 'Confirmação Pendente',
  error = 'Erro'
}

export interface Channel {
  id: string;
  status: 'active' | 'pending' | 'error';
  name: string;
  type: 'whatsapp' | 'email' | 'telegram';
  info: string;
  created_at: string;
  updated_at: string; 
}

export interface IChannelBasic {
  name: string
  type: 'whatsapp' | 'email' | 'telegram'
}

export interface ICreateChannelData {
  name?: string
  type: 'whatsapp' | 'email' | 'telegram'
  info? : string;
  trackings? : ITrackingBasic[]
}

export const defaultCreateChannelData: ICreateChannelData = {
  name : undefined,
  type : 'whatsapp',
  info : undefined
}

export type StepProps = {
  data: ICreateChannelData
  updateData: (fieldsToUpdate: Partial<ICreateChannelData>) => void
  hasError: boolean
}
