/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'

import { signInWithPopup, GoogleAuthProvider, User } from 'firebase/auth';
import { auth } from '../../../../firebase';
import Cookies from 'js-cookie';

// import {getUserByToken, login} from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

import { useAuth } from '../core/Auth';

import { login } from '../core/_requests';

export function Login() {


  const { saveAuth } = useAuth()

  const authenticate = ({ user }: { user: User }) => {

    // dispatchUserState({ type: 'login', user });
    // Get the user's ID token as it is needed to exchange for a session cookie.
    return user.getIdToken().then((idToken) => {
      // Session login endpoint is queried and the session cookie is set.
      // CSRF protection should be taken into account.
      // ...
      const csrfToken = Cookies.get('csrfToken') as string;
      return login(idToken, csrfToken).then(() => {
        saveAuth({
          idToken,
          csrfToken
        });
        window.location.reload();

      }).catch((error) => {
        saveAuth(undefined)
      });
    });
  }
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      signInWithPopup(auth, provider).then((user) => authenticate({ user: user.user }));
    } catch (error) {      
      console.error("Error signing in with Google", error);
    }
  };

  return (
    <div
      className='form w-100'
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Advogaly</h1>
      </div>
      {/* begin::Heading */}


      <div className='mb-10 bg-light-info p-8 rounded'>
        <div className='text-info text-center'>
          Utilize sua conta do Google para acessar.
        </div>
      </div>


      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-12'>
          {/* begin::Google link */}
          <a
            onClick={signInWithGoogle}
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Entrar com o Google
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}


      </div>
      {/* end::Login options */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Está com dificuldades para acessar sua conta?{' '}
        <Link to='/auth/support' className='link-primary'>
          Suporte
        </Link>
      </div>
    </div>
  )
}
