/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { defaultCreateTrackingData, ICreateTrackingData } from '../../../../models/Tracking'
import { StepperComponent } from '../../../assets/ts/components'
import { KTIcon } from '../../../helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'

import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth'


type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateTrackingModal = ({ show, handleClose }: Props) => {

  const { currentAccount } = useAuth();

  const [canCreateMore, setCanCreateMore] = useState<boolean>(false)

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateTrackingData>(defaultCreateTrackingData)
  const [hasError, setHasError] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: Partial<ICreateTrackingData>) => {
    const updatedData = { ...data, ...fieldsToUpdate }
    setData(updatedData)
  }

  const checkAppBasic = (): boolean => {
    if (!data.name || !data.type) {
      return false
    }
    return true
  }

  const checkTrackingFrequency = (): boolean => {
    if (!data.frequency) {
      return false
    }
    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (
        (data.type === 'process_number' && !data.process) 
        || (data.type === 'keyword' && !data.identifier) 
        || (data.type === 'oab' && !data.state)
        || ((data.type === 'oab' || data.type === 'cnpj' || data.type === 'cpf') && !data.court)
        || (data.type === 'cpf' && data.identifier && !isCPFValid(data.identifier))
        || (data.type === 'cnpj' && data.identifier && !isCNPJValid(data.identifier))
        || (data.type === 'oab' && data.identifier && !isOABValid(data.identifier))
      ) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }

  const submit = () => {

    if (!checkTrackingFrequency()) {
      setHasError(true)
      return
    }

    axios.post('/api/tracking', data).then(() => {
      window.location.reload()
    }).catch(console.log)

  }

  useEffect(() => {

    const subscription = currentAccount?.subscription;

    const quota = subscription?.subscriptionPlan.quota;

    const limit = subscription?.status === 'active' ? (quota?.daily_trackings || 0) + (quota?.weekly_trackings || 0) : 5;

    axios.get('/api/tracking').then(response => {

      let count = response.data.length;

      if (count < limit) {
        setCanCreateMore(true)
      };

    });

  }, []);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    > 
      <div className='modal-header'>
        <h2>Criar Monitoramento</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      {<div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        {canCreateMore ?<div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Início</h3>

                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Detalhes</h3>

                    <div className='stepper-desc'>Forneça os detalhes para configurar o monitoramento</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}


              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Frequência</h3>

                    <div className='stepper-desc'>Selecione a Frequência de Atualização</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4 */}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Canais</h3>

                    <div className='stepper-desc'>Selecione onde irá receber as notificações</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}



              {/* begin::Step 5 */}
              {/* <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper'>

                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Revisar</h3>

                    <div className='stepper-desc'>Revise o monitoramento</div>
                  </div>
                </div>

                <div className='stepper-line h-40px'></div>
              </div>  */}
              {/* end::Step 5*/}



            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <Step1 data={data} updateData={updateData} hasError={hasError} />
              <Step2 data={data} updateData={updateData} hasError={hasError} />
              <Step3 data={data} updateData={updateData} hasError={hasError} />
              <Step4 data={data} updateData={updateData} hasError={hasError} />
              {/* <Step5 data={data} updateData={updateData} hasError={hasError} /> */}



              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Anterior
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={submit}
                    // disabled={data.process ? false : true}
                  >
                    Criar <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Próximo <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div> : <div>
          <div className="alert alert-danger">Você atingiu o limite de monitoramentos. Para criar mais, atualize seu plano.</div>
          </div>}
        {/* end::Stepper */}
      </div>}
    </Modal>,
    modalsRoot
  )
}

function isCPFValid(cpf: string) {

  cpf = cpf.replace(/[^\d]+/g, '') // Remove all non-digits

  if (cpf.length !== 11) {
    return false
  }

  let sum = 0
  let rest

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  sum = 0

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}

function isCNPJValid(cnpj: string) {

  cnpj = cnpj.replace(/[^\d]+/g, '') // Remove all non-digits

  if (cnpj.length !== 14) {
    return false
  }

  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  let digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digits.charAt(0))) {
    return false
  }

  size = size + 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digits.charAt(1))) {
    return false
  }

  return true
}

function isOABValid(oab: string) {
  oab = oab.replace(/[^\d]+/g, '') // Remove all non-digits

  if (oab.length < 4 || oab.length > 6) {
    return false
  }

  return true
}

export { CreateTrackingModal }
