/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepProps } from '../../../../../models/Tracking'
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { Channel } from '../../../../../models/Channel';
import axios from 'axios';

const Step4 = ({ data, updateData, hasError }: StepProps) => {

  const [channels, setChannels] = useState([] as Channel[]);

  useEffect(() => {
  
    axios.get('/api/channel').then(response => {
      console.log(response.data)
      if (response?.data?.length) setChannels(response.data.map((channel : Channel) => ({
        value: channel.id,
        label: `${channel.name} (${channel.type})`,
        ...channel
      })));
    })

  }, [])

  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        <form>
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <div className='fv-row'>
              {/* begin::Label */}
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                <span className='required'>Canais</span>

                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Selecione a frequência de atualização'
                ></i>
              </label>
              {/* end::Label */}
              <div>

                {channels && <Select
                  // defaultValue={}
                  isMulti
                  name="colors"
                  options={channels}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange = {(e) => {
                    updateData({
                      channels: e?.map((channel : Channel) => channel.id)
                    })
                  }}
                />}


              </div>
            </div>
          </div>
        </form>
      </div>




      {/*end::Form Group */}
    </div>
  )
}

export { Step4 }
