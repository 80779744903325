/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../helpers'
import 'react-phone-input-2/lib/high-res.css'


type Props = {
  show: boolean
  handleClose: () => void,
  submit : () => void,
  objectName : string,
  objectType : string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ConfirmDeletionModal = ({ objectName, objectType, show, handleClose, submit }: Props) => {


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header'>
        <h4>Remover {objectType}</h4>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>
      <div className='flex-row-fluid py-lg-5 px-lg-15'>
        <p className='fs-5 mb-10'>Tem certeza que deseja remover {objectName}?</p>
        <button className='btn btn-danger me-10' onClick={submit}>Remover</button>
        <button className='btn btn-secondary' onClick={handleClose}>Cancelar</button>

      </div>
    </Modal>,
    modalsRoot
  )
}

export { ConfirmDeletionModal }
