import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AccountModel, AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUser, sessionLogout} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  currentAccount : AccountModel | undefined
  setCurrentAccount: Dispatch<SetStateAction<AccountModel | undefined>>
  accounts: AccountModel[] | undefined
  setAccounts: Dispatch<SetStateAction<AccountModel[] | undefined>>

  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  currentAccount: undefined,
  setCurrentAccount: () => {},
  accounts: [],
  setAccounts: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [accounts, setAccounts] = useState<AccountModel[] | undefined>([])
  const [currentAccount, setCurrentAccount] = useState<AccountModel | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    sessionLogout();
    saveAuth(undefined)
    setCurrentUser(undefined)
    setCurrentAccount(undefined)
    window.localStorage.clear();
    setAccounts([])
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, currentAccount, accounts, setCurrentAccount : (account) => { 
      setCurrentAccount(account); 
      const accountId = (account as AccountModel)?.id
      const savedAccountId = window.localStorage.getItem('account_id')
      if (accountId && savedAccountId !== accountId){
        window.localStorage.setItem('account_id', accountId);
        // window.location.reload();
      }
      }, setAccounts, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const { logout, setCurrentUser, setCurrentAccount, setAccounts} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await getUser()
          if (data) {
            const prevAccountId = window.localStorage.getItem('account_id');
            const accounts = data.accountRoles.map((accountRole: { account: AccountModel }) => accountRole.account) as AccountModel[];
            setCurrentUser(data)
            setAccounts(accounts)
            setCurrentAccount(accounts.find((account) => account.id === prevAccountId) || accounts[0])
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    // const sessionCookie = Cookies.get('session')

    requestUser()
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
