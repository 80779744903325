/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import axios from 'axios';
import { KTIcon } from '../../../helpers'
import { Tracking, TrackingStatusColor } from '../../../../models/Tracking'
import { ChannelIcon, ChannelIconColor, ChannelSatusText } from '../../../../models/Channel';
import Select from 'react-select';
import { Channel } from '../../../../models/Channel';
import { ConfirmDeletionModal } from '../../modals/confirm-deletion/ConfirmDeletionModal';

type Props = {
  className: string
}


const TrackingsTable: React.FC<Props> = ({ className }) => {

  const [trackings, setTrackings] = React.useState([] as Tracking[]);
  const [channels, setChannels] = React.useState([] as Channel[]);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = React.useState<{[key : string] : boolean}>({});
  const [editedProperties, setEditedProperties] = React.useState({name: {}, channels: {}} as {
    name: {
      [key: string]: string
    },
    channels: {
      [key: string]: string[]
    }
  });

  const [editingRow, setEditingRow] = React.useState({
    name: -1,
    channels: -1
  });

  React.useEffect(() => {
    axios.get('/api/tracking').then(response => {
      if (response?.data?.length) setTrackings(response.data);
    });

    axios.get('/api/channel').then(response => {
      if (response?.data?.length) setChannels(response.data);
    });

  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Monitoramentos</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{trackings.length} monitoramentos</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='text-start'></th>
                <th className='min-w-200px'></th>
                <th className='min-w-200x'>Tribunal</th>
                <th className='min-w-200px'>Canais</th>
                <th className='text-end'>Ações</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {trackings && trackings.map((tracking, index) => (<tr>

                <td>
                <span className={`bullet bullet-vertical h-80px w-7px  bg-${TrackingStatusColor[tracking.status]}`}></span>

                </td>
                <td>
                  
                  <div className='d-flex align-items-center'>

                    <div className='d-flex justify-content-start flex-column'>
                      {editingRow.name === index ? <div className='align-items-center input-group input-group-lg input-group-solid mb-4'>
                        <input
                          type='text'
                          className='fs-7 form-control form-control-lg form-control-solid bg-blue'
                          value={editedProperties.name[tracking.id] || tracking.name}
                          onChange={(e) => setEditedProperties({
                            ...editedProperties,
                            name : {
                              ...editedProperties.name,
                              [tracking.id]: e.target.value
                            }
                          })}

                        />

                          <a className='fs-10 btn btn-icon'             onClick={() => {
                            setEditedProperties({
                              ...editedProperties,
                              name: {
                                ...editedProperties.name,
                                [tracking.id]: tracking.name
                              }
                            })
                            setEditingRow({
                            ...editingRow,
                            name: -1
                          })}}
                        >
                          <KTIcon  iconName='cross-square' className='fs-1 text-danger' />

                          </a>

                        <a
                          className='fs-10 btn btn-icon'
                          
                          onClick={() => {

                            if (!editedProperties.name[tracking.id] || editedProperties.name[tracking.id] === tracking.name ){
                              setEditingRow({
                                ...editingRow,
                                name: -1
                              });
                              return;
                            }

                            axios.put(`/api/tracking/${tracking.id}`, {
                              name: editedProperties.name[tracking.id]
                            }).then(() => {
                              setTrackings([
                                ...trackings.slice(0, index),
                                {
                                  ...tracking,
                                  name: editedProperties.name[tracking.id]
                                } as Tracking,
                                ...trackings.slice(index + 1)
                              ]);
                              setEditingRow({
                                ...editingRow,
                                name: -1
                              });
                            }).catch((err) => {
                              console.error(err)
                            }
                            );
                          }}
                        >
                          <KTIcon iconName='check' className='fs-1 text-primary' />
                        </a>

                      </div> :
                        <div className='d-flex align-items-center'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {tracking.name}
                          </a>
                          <a
                            onClick={() => setEditingRow({
                              ...editingRow,
                              name: index
                            })}
                            className='btn btn-icon'
                          >
                            <KTIcon iconName='pencil' className='fs-7' />
                          </a>
                        </div>}

                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {tracking.process?.number}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {tracking.process?.journal_name}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {tracking.process?.journal}
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    {editingRow.channels === index ? <div className='d-flex align-items-center'>
                      <Select
                        isClearable={false}
                        isMulti
                        name="colors"
                        options={channels.map((channel) => {
                          return {
                            value: channel.id,
                            label: `${channel.name} (${channel.type})`,
                            ...channel
                          }
                        }) as Channel[]}
                        className="basic-multi-select"
                        classNamePrefix="select"

                        value={ editedProperties.channels[tracking.id] ? channels.filter((channel) =>  editedProperties.channels[tracking.id]?.includes(channel.id)).map((channel) => ({
                          value: channel.id,
                          label: `${channel.name} (${channel.type})`,
                          ...channel
                        })) : tracking.channels?.map((channel) => {
                          return {
                            value: channel.id,
                            label: `${channel.name} (${channel.type})`,
                            ...channel
                          }
                        }) as Channel[]

                        }
                        onChange={(e) => setEditedProperties({
                          ...editedProperties,
                          channels: {
                            ...editedProperties.channels,
                            [tracking.id]: e?.map((channel) => channel.id)
                          }
                        })}
                      />
                      <a 
                        className='fs-10 btn btn-icon'
                        onClick={() => {
                          
                          setEditedProperties({
                            ...editedProperties,
                            channels: {
                              ...editedProperties.channels,
                              [tracking.id]: tracking.channels?.map((channel) => channel.id)
                            }
                          })
                          setEditingRow({
                          ...editingRow,
                          channels: -1
                        })}}
                      >
                        <KTIcon iconName='cross-square' className='fs-1 text-danger' />
                      </a>

                      <a
                        className='fs-10 btn btn-icon'
                        onClick={() => {
                          axios.put(`/api/tracking/${tracking.id}`, {
                            channels : editedProperties.channels[tracking.id]
                          }).then(() => {
                            setTrackings([
                              ...trackings.slice(0, index),
                              {
                                ...tracking,
                                channels: editedProperties.channels[tracking.id]?.map((channelId) => channels.find((channel) => channel.id === channelId)) as Channel[]
                              } as Tracking,
                              ...trackings.slice(index + 1)
                            ]);
                            setEditingRow({
                              ...editingRow,
                              channels: -1
                            });
                          }).catch((err) => {
                            console.error(err)
                          }
                          );
                        }}
                      >
                        <KTIcon iconName='check' className='fs-1 text-primary' />
                      </a>
                    </div> : <>   <span>
                      <a
                        onClick={() => setEditingRow({
                          ...editingRow,
                          channels: index
                        })}
                        className='btn btn-icon'
                      >
                        <KTIcon iconName='pencil' className='fs-5' />
                      </a>
                    </span>
                      <div className='d-flex flex-column w-100 me-2'>
                        {tracking?.channels?.sort((a, b) => {
                          return a.name > b.name ? 1 : -1;
                        }).map((channel) => (
                          <div className='d-flex align-items-center'>
                            {/* <span className={`bullet bullet-vertical h-10px bg-${ChannelIconColor[channel.type]}`}></span> */}
                            {/* end::Bullet */}
                            <span>
                              <KTIcon iconName={ChannelIcon[channel.type]} className={`fs-5 text-${ChannelIconColor[channel.type]}`} />
                            </span>
                            <span style={{ margin: 2 }} className={`badge border border-solid badge-light-primary fs-8 fw-bold`}>{channel.name} {channel.status !== 'active' ? `(${ChannelSatusText[channel.status]})` : ''}</span>

                          </div>
                        ))}
                      </div></>}

                  </div>

                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      onClick={() => setShowConfirmDeletionModal({
                        ...showConfirmDeletionModal,
                        [tracking.id]: true
                      })}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                  </div>
                  <ConfirmDeletionModal objectType={`Monitoramento`} objectName={`Monitoramento de ${tracking.name}`} show={showConfirmDeletionModal[tracking.id]} handleClose={() => setShowConfirmDeletionModal({
                    ...showConfirmDeletionModal,
                    [tracking.id]: false
                  })} submit={() => {
                axios.delete(`/api/tracking/${tracking.id}`).then(response => {
                  window.location.reload();
                });
              }} />
                </td>
              </tr>))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TrackingsTable }
