import axios from 'axios'

// Server should return object => { result: boolean } (Is Email in DB)
export function getUser() {
  return axios.get('/auth/user')
}

export function login(idToken: string, csrfToken : string) {
  return axios.post('/auth/login', {
    idToken,
    csrfToken
  })
}

export function sessionLogout(){
  return axios.get('/auth/sessionLogout')
}
