import { Container, Row, Col, Card } from 'react-bootstrap';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import PaymentInfo from './PaymentInfo';
import { KTIcon } from '../../../helpers';
import PlanDisplay from './PlanDisplay';
const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
  show: boolean
  handleClose: () => void
}

const UpgradePlanModal = ({ show, handleClose }: Props) => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js or Elements hasn't loaded yet.
      return;
    }

    const paymentElement = elements.getElement(CardElement);

    if (!paymentElement) {
      return; // Guard clause to handle the case where the element isn't available for some reason
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: paymentElement,
    });

    if (error) {
      console.error(error);
      // Handle client-side error
    } else {
      const paymentMethodId = paymentMethod.id;

      await axios.post('/api/subscription', { paymentMethodId });

      window.location.reload();
    }
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
    >

      <Container >
        <div className='modal-header'>
          <h2 className='fs-2x mt-5'>Assinar Plano Profissional</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTIcon className='fs-1' iconName='cross' />
          </div>
          {/* end::Close */}
        </div>
        <div className='modal-body'>
        <Row>
          <Card>
            <Card.Body>
              <div className="text-dark fw-bold fs-20px border p-4">
                Assine o plano de teste do Advogaly para aproveitar as funcionalidades sem limites.
              </div>
            </Card.Body>
          </Card>
        </Row>
        <PlanDisplay plans={[
          { name: 'Plano Gratuito', price: 'Gratuito', features: ['1 Monitoramento', '1 Canal de Notificação'] },
          {
            name: 'Plano de Teste', price: 'R$ 50 / mês', features: ['10 Monitoramentos', '3 Canais de Notificação']
          }]} />
        <Row className='modal-body py-lg-10 px-lg-10'>
          <Col lg={6} className="mb-4">
            <Card className="h-100">
              <Card.Body>
                <div className="border p-4">
                  <CardElement options={{ hidePostalCode: true }} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <PaymentInfo />
          </Col>
        </Row>
        <Row className="justify-content-center">
  <button
    type='button'
    className='btn btn-lg btn-success margin-10 w-50 ms-2'
    onClick={handleSubmit}
  >
    Confirmar
  </button>
</Row>

                  </div>

      </Container>
    </Modal>,
    modalsRoot
  )
}


export { UpgradePlanModal }