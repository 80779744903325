/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from '../../../../../_metronic/helpers'
import { StepProps } from '../../../../../models/Tracking'

const Step1 = ({ data, updateData, hasError }: StepProps) => {
  return (
    <div className='current' data-kt-stepper-element='content'>
      <form>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Nome do Monitoramento</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Especifique um nome único para o monitoramento'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='appname'
              placeholder=''
              value={data.name}
              onChange={(e) =>
                updateData({
                  name: e.target.value,
                  type: data.type,
                })
              }
            />
            {!data.name && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  É obrigatório dar um nome
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row'>
            {/* begin::Label */}
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className='required'>Tipo de Monitoramento</span>

              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione o tipo de monitoramento'
              ></i>
            </label>
            {/* end::Label */}
            <div>
              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-primary'>
                      <KTIcon iconName='compass' className='fs-1 text-primary' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Rastrear Número de Processo</span>
                    <span className='fs-7 text-muted'>
                      Acompanhe o andamento de um processo pelo seu número único
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='type'
                    value='process_number'
                    checked={data.type === 'process_number'}
                    onChange={() =>
                      updateData({
                        name: data.name,
                        type: 'process_number',
                      })
                    }
                  />
                </span>
              </label>
              {/*end::Option */}

              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-success'>
                      <KTIcon iconName='timer' className='fs-1 text-success' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Rastrear Palavra Chave</span>
                    <span className='fs-7 text-muted'>
                      Rastreie novos processos publicados em diário oficial que contenham as palavras selecionadas
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='type'
                    value='keyword'
                    checked={data.type === 'keyword'}
                    onChange={() =>
                      updateData({
                        name: data.name,
                        type: 'keyword',
                      })
                    }
                  />
                </span>
              </label>
              {/*end::Option */}

              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-danger'>
                      <KTIcon iconName='category' className='fs-1 text-danger' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Rastrear Número OAB</span>
                    <span className='fs-7 text-muted'>
                      Rastreie novos processos vinculados a um número da OAB
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='type'
                    value='oab'
                    checked={data.type === 'oab'}
                    onChange={() =>
                      updateData({
                        name: data.name,
                        type: 'oab',
                      })
                    }
                  />
                </span>
              </label>
              {/*end::Option */}

              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-danger'>
                      <KTIcon iconName='category' className='fs-1 text-danger' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Rastrear Número de CNPJ</span>
                    <span className='fs-7 text-muted'>
                      Rastreie novos processos vinculados a um número de CNPJ
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='type'
                    value='cpnj'
                    checked={data.type === 'cnpj'}
                    onChange={() =>
                      updateData({
                        name: data.name,
                        type: 'cnpj',
                      })
                    }
                  />
                </span>
              </label>
              {/*end::Option */}

              {/*begin:Option */}
              <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='symbol symbol-50px me-6'>
                    <span className='symbol-label bg-light-danger'>
                      <KTIcon iconName='category' className='fs-1 text-danger' />
                    </span>
                  </span>

                  <span className='d-flex flex-column'>
                    <span className='fw-bolder fs-6'>Rastrear Número de CPF</span>
                    <span className='fs-7 text-muted'>
                      Rastreie novos processos vinculados a um número de CPF
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='type'
                    value='cpf'
                    checked={data.type === 'cpf'}
                    onChange={() =>
                      updateData({
                        name: data.name,
                        type: 'cpf',
                      })
                    }
                  />
                </span>
              </label>
              {/*end::Option */}

            </div>
          </div>
          {/*end::Form Group */}
        </div>
      </form>
    </div>
  )
}

export { Step1 }
