import {  Card } from 'react-bootstrap';

const PaymentInfo: React.FC = () => (
  <Card border="primary">
    <Card.Body>
      <p className="mb-2"><i className="fab fa-cc-visa text-primary"></i> <i className="fab fa-cc-mastercard text-primary"></i> <i className="fab fa-cc-amex text-primary"></i></p>
      <p>Seus dados estão seguros conosco.</p>
      <p>Powered by Stripe</p>
    </Card.Body>
  </Card>
);
export default PaymentInfo;