/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from '../../../../helpers'
import { StepProps, Tracking } from '../../../../../models/Tracking'
import { useAuth } from '../../../../../app/modules/auth';
import { useEffect, useState } from 'react';
import axios from 'axios';

const Step3 = ({ data, updateData, hasError }: StepProps) => {

  const [avaiableQuota, setAvaiableQuota] = useState({
    daily: 0,
    weekly: 0
  });

  const { currentAccount } = useAuth();

  useEffect(() => {

    const subscription = currentAccount?.subscription;

    const quota = subscription?.subscriptionPlan.quota;

    if (!subscription || subscription.status !== 'active' || !quota) {
      setAvaiableQuota({
        daily: 2,
        weekly: 3
      })
    } else {
      axios.get('/api/tracking').then(response => {

        const dailyTrackings = response.data?.filter((tracking : Tracking) => tracking.frequency === 'daily').length || 0;
        const weeklyTrackings = response.data?.filter((tracking : Tracking) => tracking.frequency === 'weekly').length || 0;
        
        setAvaiableQuota({
          daily: quota?.daily_trackings - dailyTrackings,
          weekly: quota.weekly_trackings - weeklyTrackings
        });

      });
    }

  }, [currentAccount]);


  return (
    <div className='pb-5' data-kt-stepper-element='content'>
      <div className='w-100'>
        <form>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
                <div className='fv-row'>
          {/* begin::Label */}
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Frequência</span>

            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Selecione a frequência de atualização'
            ></i>
          </label>
          {/* end::Label */}
          <div>
            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-primary'>
                    <KTIcon iconName='compass' className='fs-1 text-primary' />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Diária</span>
                  <span className='fst-italic text-danger fs-7'></span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='type'
                  value='daily'
                  disabled = {avaiableQuota.daily <= 0}
                  checked={data.frequency === 'daily'}
                  onChange={() =>
                    updateData({
                        frequency : 'daily'
                    })
                  }
                />
              </span>

              <span className='badge badge-light-primary fs-8 fw-bolder ms-2'>
                {avaiableQuota.daily} disponíveis
              </span>
            </label>
            {/*end::Option */}

            {/*begin:Option */}
            <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
              <span className='d-flex align-items-center me-2'>
                <span className='symbol symbol-50px me-6'>
                  <span className='symbol-label bg-light-danger'>
                    <KTIcon iconName='category' className='fs-1 text-danger' />
                  </span>
                </span>

                <span className='d-flex flex-column'>
                  <span className='fw-bolder fs-6'>Semanal</span>
                </span>
              </span>

              <span className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='type'
                  value='weekly'
                  checked={data.frequency === 'weekly'}
                  onChange={() =>
                    updateData({
                        frequency : 'weekly'
                    })
                  }
                />
              </span>
              <span className='badge badge-light-primary fs-8 fw-bolder ms-2'>
                {avaiableQuota.weekly} disponíveis
              </span>
            </label>
            {/*end::Option */}

          </div>
        </div>
      </div>
      </form>

      </div>


      

      {/*end::Form Group */}
    </div>
  )
}

export { Step3 }
