/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../helpers'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'

import axios from 'axios'
import { ICreateChannelData, defaultCreateChannelData } from '../../../../models/Channel'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateChannelModal = ({ show, handleClose }: Props) => {

  const [data, setData] = useState<ICreateChannelData>(defaultCreateChannelData)
  const [hasError, setHasError] = useState(false)


  const updateData = (fieldsToUpdate: Partial<ICreateChannelData>) => {
    const updatedData = { ...data, ...fieldsToUpdate }
    setData(updatedData)
  }

  const submit = () => {

    if (!data.name || !data.info || data.info?.length < 13) {
      setHasError(true)
      return
    }

    axios.post('/api/channel', data).then(() => {
      window.location.reload()
    }).catch(console.log)

  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header'>
        <h2>Criar Canal</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>
      <div className='flex-row-fluid py-lg-5 px-lg-15'>



        <form>
          <div>
            {/*begin::Form Group */}
            <div>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Nome do Canal</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Especifique um nome único para o canal'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='channel_name'
                placeholder=''
                value={data.name}
                onChange={(e) =>
                  updateData({
                    name: e.target.value,
                    type: data.type,
                  })
                }
              />
              {!data.name && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    É obrigatório dar um nome
                  </div>
                </div>
              )}
            </div>
            {/*end::Form Group */}

            {/*begin::Form Group */}
            <div className='fv-row' style={{ marginTop: 20 }}>
              {/* begin::Label */}
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
                <span className='required'>Tipo de Canal</span>

                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Selecione o tipo de canal'
                ></i>
              </label>
              {/* end::Label */}
              <div>
                {/*begin:Option */}
                <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-success'>
                        <KTIcon iconName='whatsapp' className='fs-1 text-success' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder fs-6'>WhatsApp</span>
                      <span className='fs-7 text-muted'>
                        Acompanhe o andamento de um processo pelo seu número único
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='type'
                      value='whatsapp'
                      checked={data.type === 'whatsapp'}
                      onChange={() =>
                        updateData({
                          name: data.name,
                          type: 'whatsapp',
                        })
                      }
                    />
                  </span>
                </label>
                {/*end::Option */}

                {/*begin:Option */}
                <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-danger'>
                        <KTIcon iconName='directbox-default' className='fs-1 text-danger' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder fs-6'>Email</span>
                      <span className='fst-italic text-danger fs-7'>(Em breve)</span>
                      <span className='fs-7 text-muted'>
                        Receba notificações em um email
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='type'
                      disabled
                      value='email'
                      checked={data.type === 'email'}
                      onChange={() =>
                        updateData({
                          name: data.name,
                          type: 'email',
                        })
                      }
                    />
                  </span>
                </label>
                {/*end::Option */}

                {/*begin:Option */}
                <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
                  <span className='d-flex align-items-center me-2'>
                    <span className='symbol symbol-50px me-6'>
                      <span className='symbol-label bg-light-primary'>
                        <KTIcon iconName='send' className='fs-1 text-primary' />
                      </span>
                    </span>

                    <span className='d-flex flex-column'>
                      <span className='fw-bolder fs-6'>Telegram</span>
                      <span className='fst-italic text-danger fs-7'>(Em breve)</span>
                      <span className='fs-7 text-muted'>
                        Receba notificações no Telegram
                      </span>
                    </span>
                  </span>

                  <span className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='type'
                      disabled
                      value='telegram'
                      checked={data.type === 'telegram'}
                      onChange={() =>
                        updateData({
                          name: data.name,
                          type: 'telegram',
                        })
                      }
                    />
                  </span>
                </label>
                {/*end::Option */}
              </div>
            </div>
            {/*end::Form Group */}

            {/*begin::Form Group */}
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>WhatsApp</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Especifique um nome único para o canal'
                ></i>
              </label>
              <PhoneInput
                country={'br'}
                value={data.info}
                onChange={phone => updateData({
                  info: phone
                })}
              />
                            {data?.info?.length && data.info.length < 13 && hasError ? (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Insira um telefone válido
                  </div>
                </div>
              ) : <></>}
              <div className='mt-3 fs-7 fst-italic'>
                Uma mensagem de confirmação será enviada para o número informado <span className='fw-bolder'>em até 10 minutos após a criação do canal</span>. 
              </div>
              {!data.name && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    É obrigatório definir um número de WhatsApp
                  </div>
                </div>
              )}
            </div>
            {/*end::Form Group */}
          </div>

          <div className='d-flex flex-stack' style={{ marginBottom: 10 }}>
            <button
              type='button'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='submit'
              onClick={submit}
            // disabled={}
            >
              Criar Canal <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </button>
          </div>


        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CreateChannelModal }
