import Creatable from 'react-select/creatable';

export function MultiSelectDropdownWithInput ({ id, label, options, maxSelection, description, value, setValue}: {
  id: string;
  label?: string;
  options: any;
  maxSelection?: number;
  description?: string;
  value: any[];
  setValue: (value: any) => void;
}) {

  const handleChange = (selectedOptions: any) => {
    setValue(selectedOptions);
  };

  const handleCreateOption = (inputValue: any) => {
    const newOption = { value: inputValue, label: inputValue };
    if (maxSelection && value.length >= maxSelection) {
      alert(`Você pode selecionar no máximo ${maxSelection} opções`)
      return;
    }
    setValue([...value, newOption]);
  };

  return (
    <div>
      <label htmlFor={id}>{label}</label>
      <div className='flex-help' style={{  alignItems: 'center' }}>
        <Creatable
          id={id}
          isMulti
          value={value}
          onChange={handleChange}
          onCreateOption={handleCreateOption}
          options={options}
          noOptionsMessage={() => "Digite para adicionar"}
          
        />
      </div>
    </div>
  );
};